import {
    ArrowPathIcon,
    CursorArrowRaysIcon,
    RectangleGroupIcon,
    SquaresPlusIcon,
} from '@heroicons/react/24/solid'

import {navLinks} from './getNavLinks'

type ProductLink = {
    name: 'appointments' | 'engagement' | 'automations' | 'integrations'
    href: '/#appointments' | '/#medical' | '/#finance' | '/integrations'
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
}

export default function getNavProducts() {
    return {
        links: [
            {
                name: 'appointments',
                href: navLinks.appointments as '/#appointments',
                icon: RectangleGroupIcon,
            },
            {
                name: 'engagement',
                href: navLinks.medical as '/#medical',
                icon: CursorArrowRaysIcon,
            },
            {
                name: 'automations',
                href: navLinks.finance as '/#finance',
                icon: ArrowPathIcon,
            },
            {
                name: 'integrations',
                href: navLinks.integrations as '/integrations',
                icon: SquaresPlusIcon,
            },
        ] as ProductLink[],
    } as const
}
