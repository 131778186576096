import {ChatBubbleLeftRightIcon, SparklesIcon} from '@heroicons/react/24/solid'

import {navLinks} from './getNavLinks'

type CompanyLink = {
    name: 'about' | 'contact'
    href: '/about' | '/contact'
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
}

export default function getNavCompany() {
    return {
        links: [
            {
                name: 'about',
                href: navLinks.about as '/about',
                icon: SparklesIcon,
            },
            {
                name: 'contact',
                href: navLinks.contact as '/contact',
                icon: ChatBubbleLeftRightIcon,
            },
        ] as CompanyLink[],
    } as const
}
