import classNames from 'classnames'
import {useTranslations} from 'next-intl'
import {useEffect} from 'react'

import LocaleSwitcher from '@/app/[locale]/(website)/components/LocaleSwitcher'
import getNavMobile from '@/app/[locale]/(website)/components/utils/getNavMobile'
import getNavSocial from '@/app/[locale]/(website)/components/utils/getNavSocial'
import {Link} from '@/i18n/routing'

import ThemeSwitch from '../ThemeSwitch'

export default function RenderMobileNav({mobileMenuOpen, onCloseMenu}: any) {
    const t = useTranslations('Nav')
    const {mobile} = getNavMobile()
    const {social} = getNavSocial()

    useEffect(() => {
        if (mobileMenuOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = ''
        }
        return () => {
            document.body.style.overflow = ''
        }
    }, [mobileMenuOpen])

    return (
        <ul
            className={classNames(
                'mobile-nav flex flex-col fixed left-0 w-full bg-white dark:bg-black top-[var(--navigation-height)] h-[calc(100dvh_-_var(--navigation-height))] overflow-auto p-2 lg:opacity-100 transition-opacity duration-300 ease-in-out',
                mobileMenuOpen ? 'opacity-100' : 'opacity-0',
            )}
        >
            <div className="flex flex-row justify-between h-full">
                <div>
                    {mobile.map((item) => (
                        <li
                            key={item.name}
                            className={classNames(
                                'mb-2 transition-transform duration-300 ease-in',
                                mobileMenuOpen ? 'translate-y-0' : 'translate-y-6',
                            )}
                        >
                            <Link
                                href={item.href}
                                role="link"
                                aria-label={item.name}
                                className="relative flex text-xl font-semibold text-primary hover:text-hover dark:text-dark-primary p-2"
                                onClick={onCloseMenu}
                            >
                                {t(`${item.name}.title`)}
                            </Link>
                        </li>
                    ))}
                </div>
                <div className="flex flex-row items-center h-min gap-2">
                    <div>
                        <ThemeSwitch />
                    </div>
                    <div>
                        <LocaleSwitcher />
                    </div>
                </div>
            </div>
            <ul className="flex overflow-hidden gap-2 min-h-content justify-center">
                {social.map((item) => (
                    <li key={item.name} className="group flex justify-center items-center">
                        <a
                            href={item.href}
                            aria-label={item.name}
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            className="flex items-center text-primary dark:text-dark-primary justify-center p-4 rounded-md group-hover:bg-slate-200/50"
                            onClick={onCloseMenu}
                        >
                            <svg className="size-6 text-primary dark:text-dark-primary group-hover:text-hover group-hover:dark:text-dark-hover ">
                                {item.icon}
                            </svg>
                        </a>
                    </li>
                ))}
            </ul>
        </ul>
    )
}
