'use client'
import {ChevronDownIcon} from '@heroicons/react/24/outline'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import classNames from 'classnames'
import {useTranslations} from 'next-intl'

import {Link} from '@/i18n/routing'

import {buttonClasses} from '../ui/ButtonClasses'
import getNavProducts from '../utils/getNavProducts'

interface NavProductMenuProps {
    className?: string
}

function handlePointerMove(event: React.PointerEvent) {
    event.preventDefault()
}

function handlePointerLeave(event: React.PointerEvent) {
    event.preventDefault()
}

export default function NavProductMenu({className}: NavProductMenuProps) {
    const t = useTranslations('Nav')
    const {links} = getNavProducts()

    return (
        <NavigationMenu.Item className={classNames('relative', className)}>
            <NavigationMenu.Trigger
                className={`group ${buttonClasses({
                    variant: 'navigation',
                    size: 'medium',
                    shape: 'rounded',
                    width: 'full',
                })}`}
                onPointerMove={handlePointerMove}
                onPointerLeave={handlePointerLeave}
            >
                {t('topLevel.product')}
                <ChevronDownIcon
                    className="size-4 ml-0.5 relative text-text-secondary transition-transform duration-[250] ease-in-out group-data-[state=open]:-rotate-180"
                    aria-hidden
                />
            </NavigationMenu.Trigger>
            <NavigationMenu.Content className="absolute top-full bg-bg-primary z-50 mt-1 w-max min-w-[200px] overflow-hidden rounded-xl backdrop-blur-3xl border border-border-secondary shadow-lg right-0 lg:left-0 lg:right-auto animate-scale-in">
                <ul className="flex flex-col p-2 gap-2">
                    {links.map(({name, href}) => (
                        <li key={name}>
                            <NavigationMenu.Link asChild>
                                <Link
                                    href={href}
                                    className="flex items-center justify-between w-full px-3 py-2 text-sm text-text-primary hover:bg-bg-quaternary rounded-md transition-colors"
                                >
                                    <div className="flex-auto">
                                        <span className="block text-base font-semibold">
                                            {t(`${name}.title`)}
                                        </span>
                                        <span className="text-sm text-text-tertiary">
                                            {t(`${name}.description`)}
                                        </span>
                                    </div>
                                </Link>
                            </NavigationMenu.Link>
                        </li>
                    ))}
                </ul>
            </NavigationMenu.Content>
        </NavigationMenu.Item>
    )
}
